

import { Component, Vue } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator'

@Component({
 props: {
   open:{
     default: false,
     type: Boolean
   },
   displayClose: {
     default: false,
     type: Boolean
   },
 }  
})
export default class Drawer extends Vue {
  open!:boolean;
  displayClose!:boolean;
  lastScrollTop = 50;
  scrollDirection = 0;

  //
  // helpers for typescript
  $refs!: {
    container: HTMLElement
  }  

  onScroll($event:any) {    
    const st = $event.target.scrollTop;
    //
    // downscroll code
    if (st > this.lastScrollTop){
      this.scrollDirection = 1;
    } 
    //
    // upscroll code
    else {          
      this.scrollDirection = -1;
    }

    //
    // For Mobile or negative scrolling
    this.lastScrollTop = st <= 0 ? 0 : st; 
  }
  
  @Watch('open')
  onOpenChanged(value: boolean, oldValue: boolean) {
    if(value) {
      document.body.classList.add('body-lock');
    }else{
      document.body.classList.remove('body-lock');
    }
  }



  onClose(button?:boolean){
    //
    // only quit on click button 
    if(this.displayClose && !button) {
      return;
    }

    this.$emit("close", null);
  }

}
