
import { Component, Vue } from "vue-property-decorator";
import { $config,  } from "@/services";

@Component({
})
export default class App extends Vue {

  get config() {
    return $config.config;
  }

}
